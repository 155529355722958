export const addLinksMixin = {
  methods: {
    addLinks (obj) {
      const linkIds = Object.keys(obj);
      this.$refs.editor.$el.querySelector('.prism-editor__editor').style = 'pointer-events: all;';
      Array.from(this.$refs.editor.$el.querySelectorAll('.token.string')).map(el => el.innerText);
      Array.from(this.$refs.editor.$el.querySelectorAll('.token.string')).forEach((el) => {
        const text = el.innerText.trim().replace(/"/g, '');
        if (linkIds.includes(text)) {
          const a = document.createElement('a');
          a.className = 'token string';
          a.innerHTML = text;
          a.href = obj[text];
          el.parentNode.replaceChild(a, el);
        }
      });
    },
  },
};
